import meetingApi from "../../../api/meetingApi";
import planApi from "../../../api/planApi";
import StoreTrainDBManger from "../../../db/StoreTrainDBManger";
import { Constants } from "../../../util/Constants";
import { HudGlobal } from "../../../util/HudGlobal";
import TrainFormatterHelper from "../../../util/formatter/TrainFormatterHelper";
import { getCurrentUserId, getCurrentUserOrgId } from "../../../util/user/UserManger";
import { getMeetingStates, filterPlanLeaderList, formatterMeetingState, filterManySubjectNameStr } from "../utils/MeetingFormatter";
import { gotoMeetingPrepare } from "../utils/MeetingRouter";

export default {
    name: "MeetingList",
    data() {
        return {
            formObject: {
                currentPage: 1,
                pageSize: Constants.pageSizeDefault,
                orgId: "",
                userId: "",
                planName: "", // 计划名称
                // 现场学习（0：否，1：是）
                studyOffline: 1,
                // 全部则不传, 0未开始, 1进行中, 4已完成
                state: null,
                startTime: "", // 开始时间
                endTime: "", // 结束时间
                subjectCode: "", // 类别
                supervisorUserId: null, // 会议主持人
            },
            formObjExtra: {
                meetingList: [], // 会议列表
                sourceLeaderList: [], // 会议主持人列表
                total: 0, // 总计
                periodTime: [], // 计划周期
                meetingStateList: getMeetingStates().filter((item) => item.key !== "20"),
                loading: false, // 加载状态
                loadingTip: "",
            },
        };
    },
    mounted() {
        this.setupInitParam();
        this.loadMeetingLeaderList();
        this.onSearchEvent();
    },
    methods: {
        /**
         * 查询
         */
        onSearchEvent() {
            this.monitorCurrentChange(1);
        },
        /**
         * 重置
         */
        onResetQuery() {
            this.setupInitParam();
        },
        /**
         * 初始化参数
         */
        setupInitParam() {
            this.formObject = {
                currentPage: 1,
                pageSize: 6,
                orgId: getCurrentUserOrgId(),
                userId: getCurrentUserId(),
                // 现场学习（0：否，1：是）
                studyOffline: 1,
                state: null,
                planName: "", // 计划名称
                startTime: "", // 开始时间
                endTime: "", // 结束时间
                subjectCode: this.$route.query && this.$route.query.subjectCode, // 类别
                supervisorUserId: null, // 会议主持人
            };
            // 计划周期
            this.formObjExtra.periodTime = [];
        },
        /**
         * 监听会议周期
         */
        monitorChangeMeetingPeriod(val) {
            if (val) {
                this.formObject.startTime = val[0];
                this.formObject.endTime = val[1];
            } else {
                this.formObject.startTime = null;
                this.formObject.endTime = null;
            }
        },
        /**
         * 点击跳转会议准备
         */
        onClickMeetingPrepare(item) {
            this.checkValidatePlan(item);
        },

        //=============================================网络请求=============================================//

        /**
         * 验证计划
         */
        checkValidatePlan(item) {
            const param = {
                planId: item.planId,
            };
            item.prepareLoading = true;
            meetingApi
                .getValidateMeetingPlan(param)
                .then((res) => {
                    item.prepareLoading = false;
                    if (res.success) {
                        // 保存选择的计划
                        StoreTrainDBManger.saveCurrentPlanItemToDB(item);
                        // 跳转会议准备
                        gotoMeetingPrepare({ planId: item.planId });
                    } else {
                        HudGlobal.showWarningWithMessage(res.description);
                    }
                })
                .catch(() => {
                    item.prepareLoading = false;
                });
        },

        /**
         * 加载会议列表
         */
        loadMeetingList() {
            const that = this;
            const param = this.formObject;
            this.formObjExtra.loadingTip = "会议加载中...";
            meetingApi.getMeetingPage(param).then((res) => {
                if (res.success) {
                    that.formObjExtra.meetingList = res.data || [];
                    that.formObjExtra.meetingList.forEach((element) => {
                        that.$set(element, "prepareLoading", false);
                    });
                    that.formObjExtra.total = res.total;
                } else {
                    that.formObjExtra.meetingList = [];
                    that.formObjExtra.loadingTip = "暂无现场会议";
                }
            });
        },
        /**
         *
         * 外层列表分页 page
         */
        monitorCurrentChange(val) {
            this.formObject.currentPage = val;
            this.loadMeetingList();
        },

        /**
         * 获取会议主持人
         */
        loadMeetingLeaderList() {
            const param = {
                orgId: getCurrentUserOrgId(),
            };
            const that = this;
            meetingApi.getMeetingLeaderList(param).then((res) => {
                if (res.success) {
                    that.formObjExtra.sourceLeaderList = res.data || [];
                    that.formObjExtra.sourceLeaderList.unshift({
                        userId: null,
                        realName: "所有",
                    });
                }
            });
        },

        /**
         * 是否显示会议准备按钮
         *  14 未开始 21 进行中  22 延期中  25  30
         */
        isShowMeetingPrepare(item) {
            return item.state == 14 || item.state == 20 || item.state == 21 || item.state == 22 || item.state == 25 || item.state == 30;
        },

        /**
         * 培训类别
         */
        formatterVueSubjectNameStr(item) {
            return TrainFormatterHelper.formatterManySubjectNameStr(item);
        },
    },
    filters: {
        filterPlanLeaderList,
        formatterMeetingState,
        filterManySubjectNameStr,
        /**
         *  准备培训的按钮文字
         * @param {*} item
         */
        formatterPrepareMeetingButtonText(item) {
            let state = item.state;
            state = parseInt(state);
            const finishStateList = [25, 30];
            if (finishStateList.includes(state)) {
                return "会议记录";
            } else {
                return "会议准备";
            }
        },
    },
};
